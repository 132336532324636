<template>
  <div style="position: relative;" class="px-3">
    <div v-if="loading" class="d-flex align-center justify-center" style="height: 86vh; width: 100%">
      <socket></socket>
    </div>

    <template v-else>
      <div class="d-flex align-center justify-space-between mb-1">
        <div class="d-flex align-end">
          <!-- Search for project -->
          <div class="d-flex align-center">
            <v-text-field v-model="projectSearch" @input="searchForProjects" hide-details dense outlined multiple :label="translations.findProject" background-color="#fff" palceholder="" class="project-search">
              <template v-slot:append>
                <div class="d-flex align-center justify-end project-count">
                  <span v-if="projectSearch !== ''">{{ filteredProjectsActive }} / {{ filteredProjectsLength }}</span>
                </div>
              </template>
            </v-text-field>

            <div class="d-flex align-center mr-2" style="min-width: 20px; width: 20px; height:40px;">
              <div>
                <div style="height: 20px;" class="d-flex align-top">
                  <v-btn @click="scrollToNextProject({ value: false, text: projectSearch })" icon x-small :disabled="disableScrollBtns"
                    ><v-icon color="main">{{ icons.mdiArrowUpDropCircle }}</v-icon></v-btn
                  >
                </div>
                <div style="height: 20px;" class="d-flex align-end">
                  <v-btn @click="scrollToNextProject({ value: true, text: projectSearch })" icon x-small :disabled="disableScrollBtns"
                    ><v-icon color="main">{{ icons.mdiArrowDownDropCircle }}</v-icon></v-btn
                  >
                </div>
              </div>
            </div>
          </div>

          <!-- production center select -->
          <template v-if="productionCenterList.length > 0">
            <div>
              <v-select @change="changeReadTimeline" v-model="activeProductionCentersCopy" :items="productionCenterList" hide-details dense outlined multiple :label="translations.selectProductionCenter" background-color="#fff" class="mr-2">
                <template v-slot:selection=""> </template>
              </v-select>
            </div>

            <div v-if="activeProductionCenters.length > 0">
              <div style="margin-bottom: 2px; line-height: 1">
                <small>{{ translations.activeProductionCenters }}:</small>
              </div>

              <div>
                <v-chip v-for="productionCenter in activeProductionCenters" @click:close="removeProductionCenter(productionCenter)" color="main" class="mr-1" :key="`chip-${productionCenter}`" small label outlined close>{{
                  productionCenter
                }}</v-chip>
              </div>
            </div>
          </template>
        </div>
      </div>
      <timeline v-if="!loading" @hook:mounted="setAdditionalAttributes" ref="timeline" :items="items" :groups="groups" :options="options"></timeline>
    </template>
  </div>
</template>

<script>
import readGlobalTimelineQuery from "@/graphql/ReadTimelineGlobalData.gql";

import { Timeline } from "vue2vis";
import { MomentMixin } from "@/utils/mixins/MomentMixin.js";
import { translations } from "@/utils/common";
import { mdiArrowDownDropCircle, mdiArrowUpDropCircle } from "@mdi/js";
import { Socket } from "vue-loading-spinner";

export default {
  components: {
    Timeline,
    Socket
  },

  props: {
    productionCenterList: {
      type: Array,
      default: () => []
    },
    activeProductionCenters: {
      type: Array,
      default: () => []
    }
  },

  apollo: {
    //set variables on created using props from parent
    readTimelineGlobalData: {
      query: readGlobalTimelineQuery,
      fetchPolicy: "no-cache",
      skip: true,
      result({ data }) {
        for (const project of Object.keys(data.readTimelineGlobalData)) {
          const { groups, items } = data.readTimelineGlobalData[project];

          let order = 0;

          const mappedGroups = groups
            .filter(group => group.type === "PROJECT")
            .map(group => {
              const { type, id } = group;

              if (type === "PROJECT") {
                order = group?.project?.position;
                return {
                  ...group,
                  order,
                  className: `actual-project group-id-${id}`,
                  showNested: false
                };
              }

              return {
                ...group,
                order: `${order}-${group.content}`
              };
            });

          const mappedItems = items
            .filter(item => item.itemType === "PROJECT" || item.itemType === "SUMMARY")
            .map(item => {
              const { startDateStr, endDateStr, group, itemType, warning, ...everythingElse } = item;
              const start = `${startDateStr} 00:00:00`;
              const end = `${endDateStr} 23:59:59`;
              const title = `${this.momentDate(startDateStr, "DD/MM/YYYY")} - ${this.momentDate(endDateStr, "DD/MM/YYYY")}`;

              if (itemType === "PROJECT") {
                return {
                  start,
                  end,
                  group,
                  className: `vis-item-project-scope-${warning}`,
                  itemType,
                  title,
                  ...everythingElse
                };
              } else if (itemType === "SUMMARY") {
                return {
                  start,
                  end,
                  group,
                  className: `vis-item-nested-project-${warning} vis-item-summary`,
                  itemType,
                  warning,
                  title,
                  ...everythingElse,
                  content: ""
                };
              }
            });

          this.groups.push(...mappedGroups);
          this.items.push(...mappedItems);
        }

        this.loading = false;
      }
    }
  },

  data() {
    return {
      groups: [],
      items: [],
      loading: true,
      options: {
        editable: false,
        groupEditable: false,
        locale: "it",
        align: "left",
        autoResize: true,
        orientation: "top",
        zoomable: false,
        start: `${this.formatDate(new Date())} 00:00:00`,
        end: `${this.getEndDate(200)} 23:59:59`,
        showCurrentTime: false,
        timeAxis: { scale: "week", step: 1 },
        stack: false,
        maxHeight: "86vh",
        verticalScroll: true,
        groupOrder: (a, b) => {
          return a.order > b.order ? 1 : b.order > a.order ? -1 : 0;
        },
        showMajorLabels: true,
        showMinorLabels: true,
        groupTemplate: item => {
          if (item) {
            const wrapper = document.createElement("div");
            wrapper.classList.add(`actual-project`);
            wrapper.classList.add(`project-template`);
            wrapper.classList.add(`group-id-${item.id}`);

            const projectName = document.createElement("div");
            projectName.classList.add("project-code-name", `${item.project.keyCode}`);
            projectName.innerText = item.project.keyCode + " " + item.project.label;
            projectName.title = item.content;
            wrapper.appendChild(projectName);

            return wrapper;
          }
        }
      },
      projectSearch: "",
      filteredProjectsLength: 0,
      filteredProjectsActive: 0,
      filteredProjects: [],
      activeProductionCentersCopy: []
    };
  },

  created() {
    const readTimlineVariables = {};

    if (this.activeProductionCenters.length > 0) {
      readTimlineVariables["productionCenterList"] = this.activeProductionCenters;
      this.$apollo.queries.readTimelineGlobalData.setVariables(readTimlineVariables);
    }

    this.$apollo.queries.readTimelineGlobalData.skip = false;
    this.activeProductionCentersCopy = this.activeProductionCenters;
  },

  mixins: [MomentMixin],

  methods: {
    setAdditionalAttributes() {
      // prep groups
      const groups = document.getElementsByClassName("vis-label actual-project");
      groups.forEach(group => {
        // get class attribute
        const classAttribute = group.getAttribute("class").split(" ");
        let groupId = classAttribute.find(attr => attr.includes("group-id")).split("-");
        groupId = groupId[groupId.length - 1];

        //Need to set proper status id for css class
        const foundGroup = this.groups.find(group => group.id === groupId);
        const statusId = foundGroup?.project?.projectStatus?.id;

        group.classList.add(`project-wrapper-${statusId}`);
        group.setAttribute("id", `group-${groupId}`);
      });
    },

    searchForProjects(value) {
      // const indexOfElementToSelect = this.showProjectOrderDialog ? 1 : 0;
      let lowerCaseValue = value.toLowerCase();
      const filteredProjects = [];
      const projectsToReset = [];

      this.projectCodeAndNames.forEach(project => {
        const keyCode = project.split(" ")[0];
        if (project.toLowerCase().includes(lowerCaseValue)) {
          filteredProjects.push(keyCode);
        } else {
          projectsToReset.push(keyCode);
        }
      });

      this.filteredProjectsLength = filteredProjects.length;

      //Reset non filtered projects
      projectsToReset.forEach(keyCode => {
        const el = document.getElementsByClassName(keyCode)[0];
        let innerText = el.innerText;
        el.innerHTML = innerText;
      });

      if (filteredProjects.length <= 0) {
        this.filteredProjectsActive = 0;
        return;
      }

      filteredProjects.forEach((keyCode, projectIndex) => {
        const el = document.getElementsByClassName(keyCode)[0];
        let innerHTML = el.innerHTML;
        let innerText = el.innerText;
        let innerTextLowerCase = el.innerText.toLowerCase();
        const index = innerTextLowerCase.match(lowerCaseValue).index;

        if (index >= 0) {
          innerHTML = innerText.substring(0, index) + `<span class='${projectIndex === 0 ? "active-highlight" : "highlight"}'>` + innerText.substring(index, index + value.length) + "</span>" + innerText.substring(index + value.length);
          el.innerHTML = innerHTML;
        } else {
          innerHTML = innerText;
          el.innerHTML = innerHTML;
        }
      });

      this.filteredProjects = filteredProjects;

      this.filteredProjectsActive = 1;

      document.getElementsByClassName(this.filteredProjects[0])[0].scrollIntoView(this.scrollOptions);
    },

    scrollToNextProject(payload) {
      if (this.filteredProjectsLength <= 0) return;

      const { value, text } = payload;
      // const indexOfElementToSelect = this.showProjectOrderDialog ? 1 : 0;

      let activeIndex = 0;
      if (value) {
        if (this.filteredProjectsActive + 1 <= this.filteredProjectsLength) {
          activeIndex = this.filteredProjectsActive;
          this.filteredProjectsActive++;
        } else {
          this.filteredProjectsActive = 1;
        }
        document.getElementsByClassName(this.filteredProjects[this.filteredProjectsActive - 1])[0].scrollIntoView(this.scrollOptions);
      } else {
        if (this.filteredProjectsActive - 1 === 0) {
          activeIndex = this.filteredProjectsLength - 1;
          this.filteredProjectsActive = this.filteredProjectsLength;
        } else {
          this.filteredProjectsActive--;
          activeIndex = this.filteredProjectsActive - 1;
        }
        document.getElementsByClassName(this.filteredProjects[this.filteredProjectsActive - 1])[0].scrollIntoView(this.scrollOptions);
      }

      this.filteredProjects.forEach((project, projectIndex) => {
        const el = document.getElementsByClassName(project)[0];
        let lowerCaseValue = text.toLowerCase();
        let innerHTML = el.innerHTML;
        let innerText = el.innerText;
        let innerTextLowerCase = el.innerText.toLowerCase();
        const index = innerTextLowerCase.match(lowerCaseValue).index;

        innerHTML =
          innerText.substring(0, index) + `<span class='${projectIndex === activeIndex ? "active-highlight" : "highlight"}'>` + innerText.substring(index, index + text.length) + "</span>" + innerText.substring(index + text.length);
        el.innerHTML = innerHTML;
      });
    },

    async changeReadTimeline(productionCentersValue) {
      let newProductionCenters = this.activeProductionCenters;

      if (productionCentersValue) {
        newProductionCenters = productionCentersValue;
        this.$emit("set-active-centers", productionCentersValue);
      }

      try {
        const { data } = await this.$apollo.query({
          query: readGlobalTimelineQuery,
          variables: {
            productionCenterList: newProductionCenters
          },
          fetchPolicy: "network-only"
        });

        const newGroups = [];
        const newItems = [];

        for (const project of Object.keys(data.readTimelineGlobalData)) {
          const { groups, items } = data.readTimelineGlobalData[project];

          let order = 0;

          const mappedGroups = groups
            .filter(group => group.type === "PROJECT")
            .map(group => {
              const { type, id } = group;

              if (type === "PROJECT") {
                order = group?.project?.position;
                return {
                  ...group,
                  order,
                  className: `actual-project group-id-${id}`,
                  showNested: false
                };
              }

              return {
                ...group,
                order: `${order}-${group.content}`
              };
            });

          const mappedItems = items
            .filter(item => item.itemType === "PROJECT" || item.itemType === "SUMMARY")
            .map(item => {
              const { startDateStr, endDateStr, group, itemType, warning, ...everythingElse } = item;
              const start = `${startDateStr} 00:00:00`;
              const end = `${endDateStr} 23:59:59`;
              const title = `${this.momentDate(startDateStr, "DD/MM/YYYY")} - ${this.momentDate(endDateStr, "DD/MM/YYYY")}`;

              if (itemType === "PROJECT") {
                return {
                  start,
                  end,
                  group,
                  className: `vis-item-project-scope-${warning}`,
                  itemType,
                  title,
                  ...everythingElse
                };
              } else if (itemType === "SUMMARY") {
                return {
                  start,
                  end,
                  group,
                  className: `vis-item-nested-project-${warning} vis-item-summary`,
                  itemType,
                  warning,
                  title,
                  ...everythingElse,
                  content: ""
                };
              }
            });

          newGroups.push(...mappedGroups);
          newItems.push(...mappedItems);
        }

        this.groups = newGroups;
        this.items = newItems;

        this.$nextTick(() => {
          this.setAdditionalAttributes();

          if (this.projectSearch !== "") {
            this.searchForProjects(this.projectSearch);
          }
        });
      } catch (e) {
        console.lo(e);
      }
    },

    removeProductionCenter(productionCenter) {
      this.$emit("remove-production-center", productionCenter);
      const productionCentersValue = this.activeProductionCenters.filter(pc => pc !== productionCenter);
      this.changeReadTimeline(productionCentersValue);
    }
  },

  computed: {
    translations: () => translations,

    projectCodeAndNames() {
      return this.groups
        .filter(group => group.type === "PROJECT")
        .sort((a, b) => (a.project.position > b.project.position ? 1 : b.project.position > a.project.position ? -1 : 0))
        .map(project => {
          const { keyCode, label } = project.project;
          return `${keyCode} ${label}`;
        });
    },

    icons: () => ({ mdiArrowDownDropCircle, mdiArrowUpDropCircle }),

    disableScrollBtns() {
      if (this.filteredProjectsLength <= 0) return true;
      if (!this.projectSearch) return true;
      return false;
    }
  },

  watch: {
    activeProductionCenters(newVal) {
      this.activeProductionCentersCopy = newVal;
    }
  }
};
</script>

<style>
@import "../../../../node_modules/vue2vis/dist/vue2vis.css";

.vis-text.vis-minor {
  overflow: visible !important;
}

.vis-time-axis > .vis-grid.vis-major {
  border: none !important;
}

/* .vis-item-nested-project-0 {
  background-color: black;
  color: #000;
  border-color: black;
  border-radius: 10%;
  font-size: 10px;
  max-height: 16px;
} */

.vis-item-project-scope-0 {
  background-color: #7c7c80;
  color: white;
  border-color: #7e7e7e;
  border-radius: 10%;
  font-size: 8px;
  max-height: 8px;
}

.vis-item-project-scope-1 {
  background-color: rgb(26, 45, 226);
  color: white;
  border-color: rgb(52, 62, 155);
  border-radius: 10%;
  font-size: 8px;
  max-height: 8px;
}

.vis-item-nested-project-1 {
  background-color: rgb(68, 251, 68, 0.75);
  color: #000;
  border-color: rgb(2, 186, 2, 0.9);
  border-radius: 10%;
  font-size: 10px;
  max-height: 16px;
}

.vis-item-nested-project-2 {
  background-color: rgb(250, 250, 136, 0.75);
  color: #000;
  border-color: rgb(177, 177, 0, 0.9);
  border-radius: 10%;
  font-size: 10px;
  max-height: 16px;
}

.vis-item-nested-project-3 {
  background-color: rgba(255, 0, 0, 0.75);
  color: #000;
  border-color: rgb(158, 0, 0, 0.9%);
  border-radius: 10%;
  font-size: 10px;
  max-height: 16px;
}

.vis-item-nested-project-4 {
  background-color: rgba(128, 0, 128, 0.55);
  color: #000;
  border-color: rgba(128, 0, 128, 0.55);
  border-radius: 10%;
  font-size: 10px;
  max-height: 16px;
}

.vis-item-summary {
  font-size: 10px;
  max-height: 15px;
  margin-top: 15px;
}

.highlight {
  background-color: yellow;
}

.active-highlight {
  background-color: #ff9632;
}

.project-search.v-input > .v-input__control > .v-input__slot > .v-input__append-inner {
  margin: 4px 0px;
}

.project-search.v-input > .v-input__control > .v-input__slot > .v-input__append-inner > .project-count {
  padding: 8px 0px 8px 0px !important;
  min-width: 60px;
  font-size: 14px;
}

.project-search.v-input > .v-input__append-outer {
  margin: 0px 0px 0px 2px !important;
}
</style>
